import { createApp } from 'vue'
import App from './App.vue'

import 'material-icons/iconfont/material-icons.css';

import axios from 'axios';
import VueAxious from 'vue-axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const app = createApp(App)
app.use(VueAxious, axios);
app.mount('#app_connectivity')
