import { ref, toValue } from 'vue';
import axios from 'axios';

export function useAxiosPost(url, data, params) {

    const res = ref(null);
    const error = ref(null);

    axios
        .post(toValue(url), data, params)
        .then((response) => {
            res.value = response;
            //console.log('response',response.data, res.value);
        })
        .catch(err => {
            error.value = err
        });


    return { res, error }
}
