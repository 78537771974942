<script setup>
import { ref, onMounted, watch } from "vue"
import { useAxiosPost } from '../../static/dist/composable/utils/axiosPost.js'

const url = ref('');
const actionInterval = ref(null);
const total = ref(0);
const resultObj = ref({});
const duration = ref(60000);

onMounted(() => {
  url.value = document.getElementById('connectivity_url').value;
  const obj = JSON.parse(document.getElementById('lastTimestamp').value);
  duration.value = obj.data.duration;

  resultObj.value = obj;
  
  if(url.value){
    actionInterval.value = setInterval(refresh, Number(duration.value));
    prepareMobileData();
  }
});

const refresh = () => {

  let data = {};
  data.action = 'refresh';
  data = JSON.stringify(data);

  const { res, error } = useAxiosPost(url.value, data, {});

  watch(res, (newRes) => {
    total.value++;
    //console.log(total.value);
    resultObj.value = newRes.data;
    prepareMobileData();
  });

  watch(error, (newError) => {
    console.log(newError);
  });
}

const prepareMobileData = () =>{
    
  let strIcon = ''
  let items;
  let res = Array(resultObj.value.data);

  for (items of res){
    strIcon += '<a href="#" title="' + items.title + ' - ' + items.ts + '">';
    strIcon += '<span class="material-icons btn" style="font-size:34px; margin-top:4px; margin-right: 5px; color:' + items.color +'">' + items.icon + '</span>';
    strIcon += '</a>'
  }

  //console.log(strIcon, res, items)

  const mobileApp = document.getElementById("app_connectivity_mobile");
  mobileApp.innerHTML = strIcon;

}
</script>

<template>
    <template v-for="(items, index) in resultObj" :key="index">
      <a href="#" :title="items.title + ' - ' + items.ts">
        <span class="material-icons btn" style="font-size:34px; margin-top:4px; margin-right: 5px;" :style="{ 'color': items.color }">{{ items.icon }}</span>
      </a>
    </template>
</template>

